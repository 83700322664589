<template>
  <div :style="getElementStyle" v-show="isHide" v-loading="loading">
    <div v-if="!isDefalutPos">
      <div style="display: flex">
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && hasLabel"
          :style="getLabelStyles"
          style="margin-right: 5px"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            title="Help"
            trigger="click"
            :content="data.description"
            style="margin-right: 5px"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span>
          <div
            v-if="
              isView && data.properties.filed_content != 'Hide' && !hasLabel
            "
          >
            <template v-if="form[data.key]">{{ form[data.key] }} </template>
            <template v-else>-</template>
          </div>
          <div v-else>
            <el-input
              type="text"
              :readonly="isReadOnly"
              :style="getStyle"
              v-model="form[data.key]"
              @change="checkSelectText"
            ></el-input>
          </div>
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
        </span>
      </div>
      <el-row
        v-if="
          isFromDocument
            ? ''
            : !data.properties.hideLabel &&
              data.properties.filed_content != 'Hide'
        "
      >
        <el-col>
          <span>
            <label for="text" v-if="hasLabel" :style="getLabelStyles">
              <!-- {{ data.label }} -->
            </label>
          </span>
          <span
            v-if="data.description && data.properties.filed_content != 'Hide'"
            style="color: #c0c4cc; font-weight: bold"
          >
          </span>
        </el-col>
      </el-row>
      <el-row>
        <template
          v-if="
            isFromDocument
              ? data.type == 'AUTO_INCREMENT_NUMBER'
              : data.inputType == 'AUTO_INCREMENT_NUMBER' &&
                data.properties.filed_content != 'Hide' &&
                hasLabel
          "
        >
          <input type="hidden" v-model="data.auto_increment_starting_number" />
          <input type="hidden" v-model="data.auto_increment_prefix" />
        </template>
      </el-row>
    </div>

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        style="display: contents"
        ref="colRef"
        :style="computedStyles"
      >
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && hasLabel"
          :style="getLabelStyles"
          style="margin-right: 5px"
          :class="{ 'highlight-border': highlight }"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            title="Help"
            trigger="click"
            :content="data.description"
            style="margin-right: 5px"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div v-if="isView && data.properties.filed_content != 'Hide'">
          <template v-if="form[data.key]">{{ form[data.key] }} </template>
          <template v-else>-</template>
        </div>
        <div v-else-if="isFromDocument">
          <el-input
            type="text"
            :readonly="isReadOnly"
            :style="getStyle"
            v-model="form[data.key]"
            @change="checkSelectText"
          ></el-input>
        </div>
        <div v-else-if="isView">
          <p :style="getStyle" @click="checkSelectText">{{ form[data.key] }}</p>
        </div>
        <div v-else>
          <el-input
            type="text"
            :readonly="isReadOnly"
            :style="getStyle"
            v-model="form[data.key]"
            @change="checkSelectText"
          ></el-input>
        </div>
        <p v-if="hasError" style="color: red; font-size: 14px">
          {{ errorMsg }}
        </p>
        <el-row
          v-if="
            isFromDocument
              ? ''
              : !data.properties.hideLabel &&
                data.properties.filed_content != 'Hide'
          "
        >
          <el-col>
            <span>
              <label for="text" v-if="hasLabel" :style="getLabelStyles">
              </label>
            </span>
            <span
              v-if="data.description && data.properties.filed_content != 'Hide'"
              style="color: #c0c4cc; font-weight: bold"
            >
            </span>
          </el-col>
        </el-row>
        <el-row>
          <template
            v-if="
              isFromDocument && data.value === ''
                ? data.type === 'AUTO_INCREMENT_NUMBER'
                : data.inputType == 'AUTO_INCREMENT_NUMBER' &&
                  data.properties.filed_content != 'Hide' &&
                  hasLabel
            "
          >
            <input
              type="hidden"
              v-model="data.auto_increment_starting_number"
            />
            <input type="hidden" v-model="data.auto_increment_prefix" />
          </template>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
// import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "entityDataId",
    "entityId",
    "isHideField",
    "highlight",
  ],
  mounted() {
    // #{{ data.auto_increment_prefix }}-
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    if (
      // this.data?.auto_increment_prefix &&
      this.data?.auto_increment_starting_number &&
      !this.form[this.data.key] &&
      //!this.isFromDocument &&
      this.data.input_type != "ENTITY_VARIABLE" &&
      !this.data.generate_value_from_actions
    ) {
      this.fetchAvailableNumber();
    } else if (this.form[this.data.key]) {
      this.initialValue = this.form[this.data.key];
      this.form[this.data.key +'_existed'] = this.form[this.data.key]
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("formBuilders", ["getFormBuilder"]),
    ...mapGetters("auth", [
      "getUserType",
    ]),
    ...mapGetters("entities", ["getEntityDataExists"]),
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    isHide() {
      if (this.isHideField || this.data?.properties?.filed_content == "Hide") {
        return false;
      }
      return true;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    isReadOnly() {
      return (
        this.getUserType?.name !== "OWNER" ||
        this.data.generate_value_from_actions
      );
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      previousFormData: {},
      initialValue: "",
    };
  },
  methods: {
    async checkSelectText(value) {
      this.hasError = false;
      this.errorMsg = "";
      if (value && value.startsWith(this.data.auto_increment_prefix)) {
        this.loading = true;
        let filters = [
          {
            field: this.data.template_id + "#" + this.data.key,
            operator: "=case-sensitive",
            value: value,
            value_type: "",
            value_source: "",
            data_type: "STRING",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            data_source: "VALUE",
            current_date: new Date(),
          },
        ];
        if (
          this.data?.auto_increment_mapped_field &&
          this.data.auto_increment_mapped_field.length
        ) {
          this.data.auto_increment_mapped_field.forEach((e) => {
            let [t, k] = e.split("#");
            filters.push({
              field: t + "#" + k,
              operator: "=",
              value: this.previousFormData[k],
              value_type: "",
              value_source: "",
              data_type: "STRING",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              relation_entity_id: "",
              data_source: "VALUE",
              current_date: new Date(),
            });
          });
        }
        await this.$store.dispatch("entities/checkEntityDataByFilters", {
          entityId: this.entityId,
          filters: filters,
        });
        this.loading = false;
        if (this.getEntityDataExists) {
          this.$confirm(
            "This number or value has already been taken, which may cause duplicate issues. Are you sure you want to continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.$set(this.form, this.data.key + "_donotcheck", true);
            })
            .catch(() => {
              this.$set(this.form, this.data.key, this.initialValue);
            });
        } else {
          this.$set(this.form, this.data.key + "_donotcheck", true);
        }
      } else {
        this.hasError = true;
        this.errorMsg =
          "Need to have this format: " +
          (this.data.auto_increment_prefix
            ? this.data.auto_increment_prefix
            : "") +
          this.data.auto_increment_starting_number;
      }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async fetchAvailableNumber() {
      // let autoIncrementPart;
      // if (this.isFromDocument) {
      //   const match = this.data.key.match(/auto_imcrement/g);
      //   if (match) {
      //     autoIncrementPart = match[0];
      //   }
      // }
      let params = {
        entity_id: this.entityId,
        entity_data_id: this.entityDataId ? this.entityDataId : null,
        template_id: this.data.template_id,
        formbuilder_id: this.getFormBuilder?._id,
        auto_increment_prefix: this.data.auto_increment_prefix,
        auto_increment_starting_number:
          this.data.auto_increment_starting_number,
        field_key: this.data.key,
        auto_increment_mapped_field:
          this.data.auto_increment_mapped_field || null,
        auto_increment_mapped_field_data: this.previousFormData,
      };
      // await this.$store.dispatch("templatesData/getAutoIncrementNumber", params);
      const response = await postAPICall(
        "POST",
        "/template-data/auto-increment",
        params
      );
      if (response?.data) {
        this.$set(this.form, this.data.key, response.data);
        this.initialValue = response.data;
      }
      if (
        this.data.auto_increment_starting_number &&
        this.data.auto_increment_prefix
      ) {
        this.$set(this.form, this.data.key + "_info", {
          number: this.data.auto_increment_starting_number,
          prefix: this.data.auto_increment_prefix,
        });
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "AUTO_INCREMENT_NUMBER") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
  },
  watch: {
    form: {
      async handler(data) {
        if (
          this.data.auto_increment_mapped_field &&
          this.data.auto_increment_mapped_field.length &&
          !this.data.parent_entityDataId &&
          !this.data.generate_value_from_actions
        ) {
          let currentData = {};
          this.data.auto_increment_mapped_field.forEach((e) => {
            let key = e.includes("#") ? e.split("#")[1] : e;
            currentData[key] = data[key];
          });
          if (
            JSON.stringify(this.previousFormData) !==
            JSON.stringify(currentData)
          ) {
            this.previousFormData = JSON.parse(JSON.stringify(currentData));
            this.fetchAvailableNumber();
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-border {
  color: red;
}
</style>
